import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import defaultStyle from "assets/jss/material-kit-react/views/defaultSections/defaultStyle.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class SectionError extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="message">
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <h2 className={classes.title}>Oh, my, did you just broke the Internet!?</h2>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.title}>
                  Why not create a bug report with the form below. Some poor developer will
                  take a look once he finishes his pizza and diet coke. Thanks!
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultStyle)(SectionError);
